import styled from 'styled-components'
import {
  desktopModalPaddingSize,
  media,
  phoneModalPaddingSize,
} from 'components/presentational'
import { InputRef } from 'components/presentational/input'

const desktopSize = desktopModalPaddingSize / 2

export const DecisionItem = styled.div`
  padding-top: ${desktopSize}px;

  button {
    width: 100%;
    justify-content: flex-start;
  }
`

export const LargeInput = styled(InputRef)`
  padding: 5px 2px;
  font-size: 21px;

  &::placeholder {
    color: #d9d9d9;
  }
`
