import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setStatusCodeAction,
  showNotFoundAction,
} from 'actions/system/routeAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLocation } from 'react-router'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { chatPath, contactPath, indexPath, logoutPath } from 'components/paths'
import { removeLocaleFromPath2 } from 'functions/language'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { AppStaticContext } from 'components/components.types'
import { SUPPORT_BOT_PROFILE_ID } from 'components/page/Chat/Messenger/Messenger.constants'

const supportLogoutPath = mergeAllUrls(
  chatPath,
  SUPPORT_BOT_PROFILE_ID,
  contactPath,
  logoutPath
)

export const NotFoundSwitcher: FC<{ staticContext: AppStaticContext }> = ({
  staticContext,
}) => {
  const dispatch = useDispatch()
  const { pathname } = useShallowEqualSelector(
    ({
      router: {
        location: { pathname },
      },
    }) => ({ pathname })
  )
  const { baseUrl } = useLayoutContext()
  const { pathname: locationPathname } = useLocation()
  useEffect(() => {
    /**
     * Путь меняется в зависимости авторизован пользователь или нет,
     * поэтому нужно удалить locale из baseUrl если она есть
     */
    const baseUrlWithoutLocale = mergeAllUrls(removeLocaleFromPath2(baseUrl))
    const pathnameWithoutLocale = mergeAllUrls(removeLocaleFromPath2(pathname))

    /**
     * баг с нажатием "Выход" в чате с поддержкой, когда юзер в бане
     * https://youtrack.mamba.ru/issue/M-9536
     */
    const userLogoutsFromSupport = pathnameWithoutLocale === supportLogoutPath

    if (
      locationPathname === pathname &&
      // indexPath см. https://redmine.mamba.ru/issues/106927 видимо как-то роутер
      // неправильно вычисляет
      // TODO возможно лишние условия
      pathnameWithoutLocale !== indexPath &&
      baseUrlWithoutLocale !== indexPath &&
      !userLogoutsFromSupport
    ) {
      dispatch(showNotFoundAction(true))
    }
  }, [locationPathname, pathname, baseUrl, dispatch])
  if (staticContext) {
    // Указываем, что нам нужна на сервере 404-я
    dispatch(setStatusCodeAction(404))
  }
  return null
}
